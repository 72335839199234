import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/collectif-swing/collectif-swing/src/components/page-layout.js";
import { useSiteMetadata } from '../hooks/use-site-metadata';
export const EmailLink = () => {
  const email = useSiteMetadata().contact.email;
  return <a href={`mailto:${email}`}>{email}</a>;
};
export const _frontmatter = {};
const layoutProps = {
  EmailLink,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Éditeur du site`}</h1>
    <p><strong parentName="p">{`Nom du collectif :`}</strong>{` Swing`}<br />{`
`}<strong parentName="p">{`Directrice de la publication :`}</strong>{` Léa Férée`}<br />{`
`}<strong parentName="p">{`Email :`}</strong>{` `}<EmailLink mdxType="EmailLink" /><br /></p>
    <h1>{`Hébergeur du site`}</h1>
    <p><strong parentName="p">{`Société OVH`}</strong><br />{`
`}<strong parentName="p">{`Siège social :`}</strong>{` 2 rue Kellermann - 59100 Roubaix, France`}<br />{`
`}<strong parentName="p">{`Téléphone :`}</strong>{` +33 (0)8 99 70 17 61`}<br />{`
`}<strong parentName="p">{`Site web :`}</strong>{` `}<a parentName="p" {...{
        "href": "https://www.ovh.com"
      }}>{`www.ovh.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      